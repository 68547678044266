import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  Grid,
  Modal,
  ModalPDFViewer,
  Row,
  ScrollPanel,
  SuccessDialog,
  TableData,
} from 'components/containers';
import { Button, GoBackButton, Loader, WrapperCard } from 'components/elements';
import {
  EditableConfigForm,
  FileUploader,
  Form,
  Select,
  TextField,
  TextArea,
  RadioGroup,
  useForm,
  SubmitButton,
  HiddenField,
} from 'components/form';
import { Check, Download, X } from 'components/icons';
import { useTranslationX, useTranslation } from 'i18n';
import { Profile } from 'pages/register/profile';
import { useAuthState } from 'store/auth';
import { useHistory, useParams } from 'react-router-dom';
import { FieldOption, FormInstance } from 'components/form/types';
import { useFetch, useRequest } from 'apis';
import {
  Counterpart,
  Solicitation,
  projectRequestStatusColors,
} from 'pages/project/types';

import { toSQLTimestamp } from 'utils/calendarUtils';
import { messageService } from 'services';
import { useFirstRender, useMediaQuery } from 'components/hooks';
import { TableAction } from 'components/containers/TableList/TableListItem';
import { EditorState } from 'pages/project/ProjectEditor/types';
import { ActionCounterpart } from '../Dashboard/types';
import { CounterPartModal } from '../CounterpartForm/CounterpartForm';
import { createArrayFrom1ToN } from 'utils';

import styles from './CounterpartEditor.module.scss';

type Props = EditorState & {
  actions: TableAction[];
  solicitation: Solicitation;
  dispatch: React.Dispatch<ActionCounterpart>;
};

type ProjetoData = {
  duracao: number;
  anoInicioExecucao: number;
  mesInicioExecucao: number;
};

type StateStatus = 'loading' | 'idle' | 'success';

const CounterpartEditor = () => {
  const history = useHistory<Props>();

  const { dispatch, solicitation } = history.location.state;

  const { tx } = useTranslationX('create', 'project');
  const { tx: tx2 } = useTranslationX('contrapartida', 'project');
  const { tx: tx3 } = useTranslationX('request', 'project');
  const { t } = useTranslation();

  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);
  const [desligamentoModal, setDesligamentoModal] = useState<boolean>(false);

  const [status, setStatus] = useState<StateStatus>('idle');

  const desligamentoId = useRef<number>();
  const desligamentoForm = React.createRef<FormInstance>();

  const { profile } = useAuthState();

  const { contrapartidaId, id } = useParams<{ contrapartidaId: string; id: string }>();
  const request = useRequest();

  const { data, reload } = useFetch<Counterpart>(
    `projeto/${id}/contrapartidas/${contrapartidaId}`
  );

  const matches = useMediaQuery(`(min-width: 1920px)`);

  const wrapperCardWidth = matches ? '35vw' : '43vw';

  const updateRequest = useCallback(
    (data: Counterpart, solicitacaoId: number, accept: boolean) => {
      const formData = new FormData();
      formData.append('notificationId', solicitacaoId.toString());
      if (data.estado === 'SOL') {
        formData.append('tipo', 'project.counterpart.hiring');
      } else {
        formData.append('tipo', 'project.counterpart.termination');
      }
      formData.append('accept', String(accept));
      request({
        url: `/homologacao`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onSuccess: () => {
          messageService.success(tx3('success.updateRequest'), {
            duration: 7000,
          });
          dispatch({ type: 'UPDATE' });
        },
        onError: () => {
          messageService.error(tx3('error.updateRequest'), {
            duration: 7000,
          });
        },
        onComplete: () => {
          history.goBack();
        },
      });
    },
    [dispatch, request, tx3, history]
  );

  const downloadDeclaracao = useCallback(
    (route = '/pdf') => {
      request<any>({
        url: `/projeto/${id}/contrapartidas/${contrapartidaId}${route}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
        onSuccess: (blob: any) => {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            let base64data = reader.result;
            setPdfData(base64data);
          };
        },
        onError: data => console.error(data),
      });
    },
    [contrapartidaId, id, request]
  );

  const Actions = useMemo(() => {
    const fileActions: React.ReactElement[] = [];
    const manageActions: React.ReactElement[] = [];
    if (!data) {
      return <></>;
    }

    if (
      profile === Profile.SUPERVISOR &&
      solicitation?.solicitacaoId &&
      data.estado === 'SOL'
    ) {
      fileActions.push(
        <Button
          key="accept_request"
          type="primary"
          theme="light"
          icon={Check}
          onClick={() => updateRequest(data, solicitation.solicitacaoId as number, true)}
        >
          {tx2('list.actions.accept_request')}
        </Button>
      );
      fileActions.push(
        <Button
          key="refuse_request"
          style={{
            root: {
              color: projectRequestStatusColors.CAN,
            },
          }}
          type="danger"
          theme="light"
          icon={Check}
          onClick={() => updateRequest(data, solicitation.solicitacaoId as number, false)}
        >
          {tx2('list.actions.refuse_request')}
        </Button>
      );
    }

    if (
      (!solicitation?.solicitacaoId && data.estado === 'SOL') ||
      data.estado === 'PEN'
    ) {
      fileActions.push(
        <Button
          key="download_request"
          onClick={() => downloadDeclaracao()}
          icon={Download}
          type="secondary"
        >
          {tx('downloadScienceDeclaration')}
        </Button>
      );
      if (data.estado === 'SOL' || data.termo) {
        fileActions.push(
          <Button
            key="down_request"
            onClick={() => downloadDeclaracao('/pdf/enviada')}
            type="secondary"
            icon={Download}
          >
            {tx('downloadScienceDeclarationSent')}
          </Button>
        );
      } else {
        fileActions.push(
          <Form key="upload_request">
            <ScienceUploader updateStatus={value => setStatus(value)} />
          </Form>
        );
      }
    }
    if (data.estado === 'CAD') {
      manageActions.push(
        <Button
          key="CAD"
          style={{
            root: {
              color: projectRequestStatusColors.CAN,
            },
          }}
          onClick={() => {
            setDesligamentoModal(true);
            desligamentoId.current = parseInt(contrapartidaId);
          }}
          icon={X}
          type="danger"
          theme="light"
        >
          {tx('personFired')}
        </Button>
      );
    }
    return (
      <div className={styles.actions}>
        <div className={styles.file}>{fileActions}</div>
        <div className={styles.manage}>{manageActions}</div>
      </div>
    );
  }, [
    contrapartidaId,
    data,
    downloadDeclaracao,
    profile,
    solicitation?.solicitacaoId,
    tx2,
    updateRequest,
    tx,
  ]);

  if (!data) {
    return <Loader />;
  }

  const handleClose = () => {
    setPdfData(null);
  };

  return (
    <>
      <SuccessDialog
        onClose={() => {
          setStatus('idle');
          history.goBack();
        }}
        visible={status === 'success'}
        title={tx3('success.uploadScienceDeclaration')}
        buttonLabel={t('button.ok')}
      />
      <Modal visible={status === 'loading'} minWidth={false} delay={500}>
        <Loader
          type="grid"
          delay={false}
          message={t(`components.form.async.submitting`, { defaultValue: ' ' })}
        />
      </Modal>
      <ScrollPanel>
        <div className={styles.counterpartView}>
          <div className={styles.header}>
            <GoBackButton />
            {Actions}
          </div>

          <WrapperCard minWidth={wrapperCardWidth}>
            <div className={styles.scrollContent}>
              <ScrollPanel vBar={{ overlay: true }}>
                <EditableConfigForm
                  baseUrl={`projeto/${id}/contrapartidas/${contrapartidaId}`}
                  overrideEditable={data.editable}
                  onSuccess={() => history.goBack()}
                  {...(data.estado === 'PEN' && { successMessage: tx2('editSuccess') })}
                >
                  <Content {...data} />
                </EditableConfigForm>
              </ScrollPanel>
            </div>
          </WrapperCard>

          <Modal
            visible={pdfData !== null}
            width="auto"
            onBlur={handleClose}
            onClose={handleClose}
          >
            <div className={styles.popup}>
              <ModalPDFViewer
                pdfData={pdfData}
                onClose={handleClose}
                title={tx('scienceDeclaration')}
              />
            </div>
          </Modal>
          <Modal
            visible={desligamentoModal}
            width={400}
            onBlur={() => setDesligamentoModal(false)}
            onClose={() => setDesligamentoModal(false)}
          >
            <div className={styles.popup}>
              <p className={styles.modalTitle}>{tx2('desligamentoMonth')}</p>

              <Form
                ref={desligamentoForm}
                baseUrl={`/projeto/${id}/contrapartidas/desligamento`}
                onDone={() => {
                  reload();
                  setDesligamentoModal(false);
                  messageService.success(tx3('success.requestRequired'), {
                    duration: 7000,
                  });
                }}
              >
                <HiddenField name="id" value={desligamentoId.current} />
                <Select name="mes" label={tx2('desligamentoLabel')} />
                <div>
                  <SubmitButton>{tx2('confirmDesligamento')}</SubmitButton>
                </div>
              </Form>
            </div>
          </Modal>
        </div>
      </ScrollPanel>
    </>
  );
};

const Content = (data: Counterpart) => {
  const { tx } = useTranslationX('create', 'project');
  const { tx: tx2 } = useTranslationX('components.month_year_picker');

  const { t } = useTranslation('project');
  const form = useForm();
  const firstRender = useFirstRender();

  const { id } = useParams<{ contrapartidaId: string; id: string }>();

  const { data: projetoData } = useFetch<ProjetoData>(`/projetos/${id}/projeto`);

  useEffect(() => {
    if (firstRender) {
      if (!data.editable) {
        messageService.warning(tx('openSolicitation'), {
          duration: 2000,
        });
      }
    }
  }, [data, tx, form, firstRender]);

  const onPopulateData = (data: any) => {
    if (data) {
      form.setFieldValue('nome', data.nome);
    }
  };

  if (!projetoData) return <Loader />;

  const renderDuration = (relativeMonth: number) => {
    const currentYear = projetoData.anoInicioExecucao;

    const month = projetoData.mesInicioExecucao - 1 + relativeMonth;

    const currentMonth = month > 12 ? month % 12 : month;
    const year = currentYear + Math.floor((month - 1) / 12);

    return `${relativeMonth} - ${tx2(`months.${currentMonth}`).toLowerCase()}/${year}`;
  };

  return (
    <>
      {data.estado === 'PEN' && form.editable ? (
        <Grid maxWidth="250px">
          <Row>
            <CounterPartModal
              onPopulateData={onPopulateData}
              title={t('request.form.person.title')}
              label={tx('selectPerson')}
              placeholder={tx('select')}
            />
          </Row>
        </Grid>
      ) : (
        <></>
      )}

      <Grid maxWidth="900px">
        <Row width={[5, 3, 2]}>
          <TextField
            type="readonly"
            label={tx('name')}
            name="nome"
            placeholder={tx('professionalName')}
          />
        </Row>
      </Grid>

      <Grid>
        <Row>
          <h3 className={styles.formPeriod}>{tx('atuationPeriod')}</h3>
        </Row>
      </Grid>
      <Grid maxWidth="680px">
        <Row width={[5, 5, 2]}>
          <Select
            name="mesInicio"
            label={tx('contractStartMonth')}
            metadata={{
              options: createArrayFrom1ToN(projetoData.duracao).map(value => {
                return { text: renderDuration(value), value: value };
              }),
            }}
          />

          <Select
            name="mesFim"
            label={tx('contractEndMonth')}
            metadata={{
              options: createArrayFrom1ToN(projetoData.duracao).map((value: number) => {
                return {
                  text: renderDuration(value),
                  value: value,
                };
              }),
            }}
          />

          <TextField
            label={tx('workload')}
            name="chMes"
            placeholder="00"
            adornment={{ right: tx('hours') }}
            metadata={{
              rules: {
                mask: {
                  pattern: '0##',
                },
              },
            }}
          />
        </Row>
      </Grid>
      <Grid>
        <Row>
          <h3 className={styles.formPeriod}>{tx('vacationPeriod')}</h3>
        </Row>
      </Grid>
      <TableData
        name="ferias"
        textSize="small"
        addLabel={tx('vacationPeriod')}
        style={{ table: { maxWidth: 600 } }}
        config={[
          {
            title: tx('contractStartDate'),
            field: 'dataInicio',
            type: 'date',
            align: 'left',
            parse: value => toSQLTimestamp(value),
          },
          {
            title: tx('contractEndDate'),
            field: 'dataFim',
            type: 'date',
            align: 'left',
            parse: value => toSQLTimestamp(value),
          },
        ]}
      />

      <Grid maxWidth="500px">
        <Row width={[0.3, 0.5]} align="baseline">
          <TextField
            label={tx('contractType')}
            name="regime"
            placeholder="00"
            adornment={{ right: tx('hours') }}
            metadata={{
              rules: {
                mask: {
                  pattern: '0##',
                },
                required: true,
              },
            }}
          />

          <TextField
            label={tx('remuneration')}
            name="remuneracao"
            adornment={{ left: tx('currency') }}
            metadata={{
              rules: {
                required: true,
              },
            }}
          />
        </Row>
      </Grid>
      <Grid maxWidth="500px">
        <Row width={[0.3, 0.5]} align="baseline">
          <RadioGroup
            label={tx('tipoServidor')}
            name="tipoServidor"
            columns={2}
            parseOption={(value: FieldOption) => tx(`${value.text}`)}
            metadata={{
              options: ['TAE', 'DOC'].map(value => {
                return { text: value, value };
              }),
              rules: {
                required: true,
              },
            }}
          />
        </Row>
      </Grid>
      <Grid maxWidth="600px">
        <Row>
          <TextArea label={tx('activities')} minRows={5} name="atividades" />
        </Row>
      </Grid>
    </>
  );
};

type ScienceUploaderProps = {
  updateStatus?: (status: StateStatus) => void;
};

const ScienceUploader = ({ updateStatus }: ScienceUploaderProps) => {
  const { tx } = useTranslationX('create', 'project');

  const { contrapartidaId, id } = useParams<{ contrapartidaId: string; id: string }>();

  const request = useRequest();

  const [visible, setVisible] = useState(false);
  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);
  const [file, setFile] = useState<File>();

  const openAceiteUpload = useCallback((data: File[]) => {
    const file = data[0];

    setFile(file);

    file.arrayBuffer().then(arrayBuffer => {
      setPdfData(arrayBuffer);
      setVisible(true);
    });
  }, []);

  const handleClose = () => {
    setVisible(false);
    setPdfData(null);
    setFile(undefined);
  };

  const handleSendDeclaration = useCallback(() => {
    if (!file) return;
    let formData = new FormData();
    formData.append('declaracao', file as File);
    updateStatus?.('loading');

    request<any>({
      url: `/projeto/${id}/contrapartidas/${contrapartidaId}/pdf`,
      method: 'PUT',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
      onSuccess: () => {
        updateStatus?.('success');
      },
      onError: data => {
        console.error(data);
        updateStatus?.('idle');
      },
    });
  }, [contrapartidaId, id, request, updateStatus, file]);

  return (
    <>
      <FileUploader
        name="termo"
        type="button"
        message={tx('uploadScienceDeclaration')}
        metadata={{ rules: { accept: ['pdf'], maxfiles: 1, filesize: 5242880 } }}
        onFileUpload={openAceiteUpload}
      />
      <Modal visible={visible} onBlur={handleClose} onClose={handleClose}>
        <ModalPDFViewer
          pdfData={pdfData}
          onClose={handleClose}
          title={tx('scienceDeclaration')}
          onCancel={handleClose}
          onUpload={handleSendDeclaration}
        />
      </Modal>
    </>
  );
};

export default CounterpartEditor;
