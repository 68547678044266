import React, { useCallback, useEffect, useRef, useState } from 'react';

import { MdCheck as Check } from 'react-icons/md';

import { useTranslation, useTranslationX } from 'i18n';

import { Modal, ModalPDFViewer, TableList } from 'components/containers';
import { ColumnConfig } from 'components/containers/TableList/types';
import { Instance } from 'components/containers/TableList/TableList';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useAuthState } from 'store/auth';
import { Profile } from 'pages/register/profile';
import {
  ProjectCounterpartStatus,
  Solicitation,
  projectCounterpartStatusColors,
  projectRequestStatusColors,
} from 'pages/project/types';
import { FileUploader, Form, HiddenField, Select, SubmitButton } from 'components/form';
import { FormInstance } from 'components/form/types';
import { PDF, X } from 'components/icons';
import { useFetch, useRequest } from 'apis';
import { Loader } from 'components/elements';
import { ActionCounterpart, CounterpartState } from '../Dashboard/types';
import { TableAction } from 'components/containers/TableList/TableListItem';

import styles from '../CounterpartEditor/CounterpartEditor.module.scss';

type Item = {
  id: number;
  rownum: number;
  nome: string;
  email: string;
  lattes: string;
  titulacao: string;
  area_formacao: string | undefined;
  area_pesquisa: string;
  titulacao_id: number;
  categoria: string;
  estado: string;
  solicitacaoId: number;
};

type TableActionsMap = {
  [key: string]: TableAction[];
};

const CounterpartList = ({
  state,
  dispatch,
}: {
  state: CounterpartState;
  dispatch: React.Dispatch<ActionCounterpart>;
}) => {
  const { tx } = useTranslationX('contrapartida', 'project');
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();

  const { profile } = useAuthState();
  const request = useRequest();
  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [desligamentoModal, setDesligamentoModal] = useState<boolean>(false);
  const [rowSelected, setRowSelected] = useState<any>();
  const [sentDeclaration, setSentDeclaration] = useState(false);
  const form = React.createRef<FormInstance>();
  const desligamentoId = useRef();
  const desligamentoForm = React.createRef<FormInstance>();

  const { data: projetoData } = useFetch<{ duracao: number }>(`/projetos/${id}/projeto`);

  const table = useRef<Instance>();
  const actionsArray = useRef<TableActionsMap>();

  useEffect(() => {
    table.current?.reload();
  }, [state.lastUpdate]);

  const downloadDeclaracao = (data: any, path = '/pdf/enviada') => {
    request<any>({
      url: `/projeto/${id}/contrapartidas/${data.id}${path}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      onSuccess: (blob: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          setPdfData(base64data);
        };
      },
      onError: data => console.error(data),
    });
  };

  const sendDeclaracao = () => {
    let formData = new FormData();
    const file = (form.current?.context.getFieldValue('declaracao') as File[])[0];
    formData.append('declaracao', file);

    request<any>({
      url: `/projeto/${id}/contrapartidas/${rowSelected.id}/pdf`,
      method: 'PUT',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
      onSuccess: (blob: any) => {
        dispatch({ type: 'UPDATE' });
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          setPdfData(base64data);
          setVisible(false);
          setSentDeclaration(true);
        };
      },
      onError: data => console.error(data),
    });
  };

  const pushToDetails = useCallback(
    ({ id: solicitacaoId, ...rest }: Solicitation) => {
      if (actionsArray.current) {
        const actions: TableAction[] = actionsArray.current[solicitacaoId]?.map(
          (action: TableAction) => {
            return {
              color: action.color,
              label: action.label,
              identifier: action.identifier,
            };
          }
        );

        if (actions) {
          history.push(`/dashboard/projetos/${id}/contrapartida/${solicitacaoId}`, {
            actions,
            solicitation: {
              id: solicitacaoId,
              ...rest,
            },
          });
        }
      }
    },
    [history, id]
  );

  const getActions = (data: any) => {
    const actions = [];
    if (profile === Profile.SUPERVISOR) {
      if (data.estado === 'SOL') {
        actions.push({
          icon: PDF,
          onClick: () => {
            downloadDeclaracao(data);
            if (data.solicitacaoId) {
              setSentDeclaration(true);
            }
          },
          color: '#333333',
          label: tx('list.actions.download_office'),
        });
      }
      if (data.estado === 'SOL' || data.estado === 'RQF') {
        actions.push({
          icon: Check,
          onClick: () => {
            pushToDetails(data);
          },
          color: '#333333',
          label: tx('list.actions.accept_request'),
        });
        actions.push({
          icon: X,
          onClick: () => {
            pushToDetails(data);
          },
          color: projectRequestStatusColors.REF,
          label: tx('list.actions.refuse_request'),
        });
      }
    } else {
      if (data.estado === 'PEN') {
        actions.push({
          icon: PDF,
          onClick: () => downloadDeclaracao(data, '/pdf'),
          color: '#333333',
          label: tx('list.actions.download_office'),
        });
        actions.push({
          icon: PDF,
          onClick: () => {
            setVisible(true);
            setRowSelected(data);
          },
          color: projectRequestStatusColors.ACC,
          label: tx('list.actions.upload_office'),
        });
      }
      if (data.estado === 'SOL') {
        actions.push({
          icon: PDF,
          onClick: () => {
            downloadDeclaracao(data);
            if (data.solicitacaoId) {
              setSentDeclaration(true);
            }
          },
          color: '#333333',
          label: tx('list.actions.download_office'),
        });
      }
      if (data.estado === 'CAD') {
        actions.push({
          icon: X,
          onClick: () => {
            setDesligamentoModal(true);
            desligamentoId.current = data.id;
          },
          color: projectRequestStatusColors.CAN,
          label: tx('list.actions.termination'),
        });
      }
    }
    return actions;
  };

  const config: ColumnConfig = [
    {
      title: tx('tableHeaders.name'),
      field: 'nome',
      weight: 0.3,
      type: 'html',
      align: 'left',
      search: true,
      parse: value => `<strong>${value}</strong>`,
    },
    {
      title: tx('tableHeaders.initialMonth'),
      field: 'mesInicio',
      weight: 0.12,
      search: true,
      searchType: 'range',
      type: 'number',
      align: 'center',
    },
    {
      title: tx('tableHeaders.endMonth'),
      field: 'mesFim',
      weight: 0.12,
      search: true,
      searchType: 'range',
      type: 'number',
      align: 'center',
    },
    {
      title: tx('tableHeaders.workload'),
      field: 'chMes',
      weight: 0.15,
      align: 'center',
      search: true,
      metadata: {
        options: [20, 40].map(status => {
          return {
            value: status,
            text: status,
          };
        }),
      },
      searchType: 'status',
      parse: status => {
        return {
          value: status,
          bgColor: 'gray',
        };
      },
    },
    {
      title: tx('tableHeaders.duration'),
      field: 'duracao',
      search: true,
      weight: 0.15,
      align: 'center',
    },
    {
      title: tx('tableHeaders.remuneration'),
      field: 'remuneracao',
      searchType: 'range',
      weight: 0.15,
      capitalize: true,
      align: 'left',
      search: true,
      parse: value => `${t('currency')} ${value}`,
    },
    {
      title: tx('tableHeaders.status'),
      field: 'estado',
      type: 'tag',
      weight: 0.15,
      search: true,
      metadata: {
        options: Object.keys(projectCounterpartStatusColors).map(status => {
          return {
            value: status,
            text: tx(`status.${status}`),
          };
        }),
      },
      searchType: 'status',
      parse: status => {
        return {
          value: tx(`status.${status}`),
          bgColor: projectCounterpartStatusColors[status as ProjectCounterpartStatus],
        };
      },
    },
    {
      title:
        profile === Profile.SUPERVISOR
          ? tx('tableHeaders.supervisorAction')
          : tx('tableHeaders.researcherAction'),
      field: 'coordinatorAction',
      weight: 0.2,
      align: 'left',
      type: 'actions',
      sort: false,
      parse(_, data) {
        const actions = getActions(data);
        actionsArray.current = {
          ...actionsArray.current,
          [data.id]: actions,
        };
        return actionsArray.current[data.id];
      },
    },
  ];

  const editAction = ({ id, ...rest }: Item) => {
    if (id !== undefined) {
      history.push(`${location.pathname}/${id}`, { ...rest });
    } else {
      history.push(`${location.pathname}/nova-contratacao`);
    }
  };

  if (!projetoData) {
    return <Loader type="spin" />;
  }

  const handleClose = () => {
    setPdfData(null);
    setSentDeclaration(false);
  };

  return (
    <>
      <div style={{ width: '80%' }}>
        <TableList<Item>
          baseUrl={`/projeto/${id}/contrapartidas/contratacoes`}
          listHeader={{
            subtitle: tx('tableHeaders.title'),
            title: tx('tableHeaders.subtitle'),
            position: 'inside',
          }}
          style={{
            root: {
              maxHeight: 'calc(100vh - 150px)',
            },
          }}
          textSize="small"
          theme="light"
          addButton={{
            type: 'green',
            label: profile === Profile.SUPERVISOR ? undefined : tx('newContract'),
          }}
          config={config}
          reference={table}
          editAction={editAction}
          keyComposer={(item, index) => `${item.id}-${index}`}
          editIcon={null}
          refreshesOn={95}
          pageSize={5}
          notFoundProps={{}}
          filter
        />
      </div>
      <Modal
        visible={pdfData !== null}
        width="auto"
        onBlur={handleClose}
        onClose={handleClose}
      >
        <div className={styles.popup}>
          <ModalPDFViewer
            pdfData={pdfData}
            type={sentDeclaration ? 'view' : 'download'}
            title={tx('declaration')}
            onClose={handleClose}
          />
        </div>
      </Modal>
      <Modal
        visible={visible}
        width={400}
        onBlur={() => setVisible(false)}
        onClose={() => setVisible(false)}
      >
        <div className={styles.popup}>
          <p className={styles.modalTitle}>{tx('makeUpload')}</p>
          <Form ref={form} onSubmit={sendDeclaracao}>
            <FileUploader
              message={tx('fileUploadButton')}
              metadata={{ rules: { accept: ['pdf'], maxfiles: 1, filesize: 5242880 } }}
              name="declaracao"
            />
            <div>
              <SubmitButton>{tx('fileUpload')}</SubmitButton>
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        visible={desligamentoModal}
        width={400}
        onBlur={() => setDesligamentoModal(false)}
        onClose={() => setDesligamentoModal(false)}
      >
        <div className={styles.popup}>
          <p className={styles.modalTitle}>{tx('desligamentoMonth')}</p>

          <Form
            ref={desligamentoForm}
            baseUrl={`/projeto/${id}/contrapartidas/desligamento`}
            onDone={() => {
              dispatch({ type: 'UPDATE' });
              setDesligamentoModal(false);
            }}
          >
            <HiddenField name="id" value={desligamentoId.current} />
            <Select name="mes" label={tx('desligamentoLabel')} />
            <div>
              <SubmitButton>{tx('confirmDesligamento')}</SubmitButton>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default CounterpartList;
